import React, { useEffect, useState, useContext } from 'react';
import { Typography } from 'antd';
import GenericTable from '../../atoms/generic-table/generic-table';
import { SiteContext } from '../../../services/providers/site-context';
import { useRedirect } from '../../router/redirect';
import apiCalls from '../../../services/api-calls/all';
import GenericActions from '../../molecules/table-columns/generic-actions/generic-actions.table-columns';
import { sitesColumns } from '../../molecules/table-headers/sites.table-header';
import { ENTER, MEDIA_URL, MEDIA_LTI_URL, LANDING_LTI_URL } from '../../../utils/constants-utils';
import { errorLogger } from '../../../utils/generic-utils';

const { Title } = Typography;
const { getSitesList } = apiCalls();
const SitesList = () => {
  const params = new URLSearchParams(window.location.search);
  const isLti = params.get('isLti');
  const ownerId = params.get('ownerId');
  const idToken = params.get('id_token');
  const isDeepLink = params.get('isDeepLink');
  const [siteIdSelected, setSiteIdSelected] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const { setSite } = useContext(SiteContext);
  const { redirect, setUrlToRedirect } = useRedirect();

  const handleEnterClick = (record) => {
    setSite({ SiteId: record.key, SiteName: record.site_name });
    console.log(`isLti: ${isLti}, isLti==='true': ${isLti === 'true'}`);
    console.log(`isDeepLink: ${isDeepLink}, isDeepLink === 'true': ${isDeepLink === 'true'}`);
    if (isDeepLink === 'true') {
      // on the Rich Content Editor in Canvas, deep linking a video
      setSiteIdSelected(record.key);
      setUrlToRedirect(`${MEDIA_LTI_URL}`);
      console.log('Redirecting to Media Lti Url');
      redirect({ siteId: record.key, isDeepLink });
    } else if (isLti === 'true') {
      // on the MediaPlus page in Canvas
      console.log('Redirecting to Home page');
      setSiteIdSelected(record.key);
      setUrlToRedirect(LANDING_LTI_URL);
      redirect({ siteId: record.key, isDeepLink: false });
    } else {
      // normal Media Plus website access
      console.log('Redirecting to Home page');
      setSiteIdSelected(record.key);
      setUrlToRedirect(MEDIA_URL);
      redirect({ siteId: record.key, isDeepLink: false });
    }
  };

  const checkForAll = (allSites) => {
    // Check if the user has an auth record with 'ALL' in the siteId column.
    // If so, remove it and return all sites
    let results = [];
    console.log(`sites in checkForAll: ${JSON.stringify(allSites)}`);
    const hasAllSitePermissions = allSites.Items.filter(
      (site) => site.SiteId.toLowerCase() === 'all'
    );
    console.log(`hasAllSitePermissions = ${JSON.stringify(hasAllSitePermissions)}`);
    if (hasAllSitePermissions && hasAllSitePermissions.length > 0) {
      // In the edge case where an administrator has the 'ALL' record
      // in the authz table, we cannot assume they have individual authz
      // records for each site.  So we need to scan the Sites table to get all sites
      console.log('returning all sites');
      results = allSites;
    } else {
      allSites.Items.map((site) => {
        if (site.SiteId.toLowerCase() !== 'all') {
          results.push(site);
        }
        return site;
      });
    }
    return results;
  };

  const actions = [{ key: ENTER, onClick: handleEnterClick }];

  const tableColumns = [...sitesColumns, GenericActions({ actions })];
  useEffect(async () => {
    setTableLoading(true);
    try {
      const allSites = await getSitesList();
      // check for 'All' sites ownership
      const allowedSites = checkForAll(allSites.data);
      const activeSites = allowedSites
        .filter((item) => item.Active)
        .map((item) => ({
          site_name: item.SiteName,
          key: item.SiteId,
        }));
      try {
        console.log('allowed sites = ' + JSON.stringify(activeSites));
      } catch (error) {
        console.log('Exception caught in index.js: ' + error);
      }
      setDataSource(activeSites);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while geting sites list:', error });
    }
    setTableLoading(false);
  }, []);
  let renderParams = {};
  if (isLti) {
    renderParams = {
      siteId: siteIdSelected,
      ownerId,
      id_token: idToken,
      isDeepLink,
    };
    console.log(`renderParams going to lti media page: ${JSON.stringify(renderParams)}`);
  } else {
    console.log('isLti is false in 2nd check');
  }
  return (
    <div>
      {redirect(renderParams)}
      <div>
        <Title>Sites</Title>
      </div>
      <div>
        <GenericTable columns={tableColumns} data={dataSource} loading={tableLoading} />
      </div>
    </div>
  );
};

export default SitesList;
