/* eslint-disable quotes */
module.exports = {
  // PAGES URLS
  LANDING_URL: '/',
  LANDING_LTI_URL: '/lti',
  HOME_URL: '/home',
  MEDIA_URL: '/media',
  MEDIA_LTI_URL: '/lti/media',
  MEDIA_UPLOAD_URL: '/mediaUpload',
  MEDIA_UPLOAD_LTI_URL: '/lti/upload/media',
  MEDIA_EDIT_URL: '/mediaEdit',
  MEDIA_PLAYER_URL: '/mediaPlayer',
  COLLECTIONS_URL: '/collections',
  CREATE_COLLECTION_URL: '/createCollection',
  EDIT_COLLECTION_URL: '/editCollection',
  PLAYLISTS_URL: '/playlists',
  PLAYLISTS_LTI_URL: '/lti/playlists',
  PLAYLIST_PLAYER_URL: '/playlistsPlayer',
  EMBEDDED_PLAYLIST_PLAYER_URL: '/embedded/playlist',
  EMBEDDED_PLAYLIST_PLAYER_LTI_URL: '/lti/embedded/playlist',
  CREATE_PLAYLIST_URL: '/createPlaylist',
  CREATE_PLAYLIST_LTI_URL: '/lti/create/playlist',
  EDIT_PLAYLIST_URL: '/editPlaylist',
  SITES_ADMIN_URL: '/sitesAdmin',
  SITES_LIST_URL: '/sitesList',
  SITES_LIST_LTI_URL: '/lti/sitesList',
  HELP_URL: '/help',
  HELP_LTI_URL: '/lti/help',
  RELEASE_NOTES_URL: '/release_notes',
  FAQ_URL: '/faq',
  SETTINGS_URL: '/settings',
  EMBEDDED_PLAYER_URL: '/embedded',
  EMBEDDED_PLAYER_LTI_URL: '/lti/embedded',
  MANAGE_USERS_URL: '/manageUsers',
  HEALTH_URL: '/health',
  VERSION_URL: '/version',
  AUTHZ_LIST_URL: '/authzList',
  AUTHZ_LIST_LTI_URL: '/lti/authzList',

  // PAGES KEYS
  LANDING_KEY_PAGE: 'landing',
  LANDING_LTI_KEY_PAGE: 'landing',
  HOME_KEY_PAGE: 'home',
  MEDIA_KEY_PAGE: 'media',
  MEDIA_LTI_KEY_PAGE: 'media_lti',
  MEDIA_UPLOAD_KEY_PAGE: 'media_upload',
  MEDIA_UPLOAD_LTI_KEY_PAGE: 'media_upload_lti',
  MEDIA_EDIT_KEY_PAGE: 'media_edit',
  MEDIA_PLAYER_KEY_PAGE: 'media_player',
  COLLECTIONS_KEY_PAGE: 'collections',
  CREATE_COLLECTION_KEY_PAGE: 'create_collection',
  EDIT_COLLECTION_KEY_PAGE: 'edit_collection',
  PLAYLISTS_KEY_PAGE: 'playlists',
  PLAYLISTS_LTI_KEY_PAGE: 'playlists_lti',
  PLAYLIST_PLAYER_KEY_PAGE: 'playlists_player',
  EMBEDDED_PLAYLIST_PLAYER_KEY_PAGE: 'embedded_playlist_lti',
  EMBEDDED_PLAYLIST_PLAYER_LTI_KEY_PAGE: 'embedded_playlist_lti',
  CREATE_PLAYLIST_KEY_PAGE: 'create_playlist',
  CREATE_PLAYLIST_LTI_KEY_PAGE: 'create_playlist_lti',
  EDIT_PLAYLIST_KEY_PAGE: 'edit_playlist',
  SITES_ADMIN_KEY_PAGE: 'sites_admin',
  SITES_LIST_KEY_PAGE: 'sites_list',
  HELP_KEY_PAGE: 'help',
  RELEASE_NOTES_KEY_PAGE: 'release_notes',
  FAQ_KEY_PAGE: 'faq',
  SETTINGS_KEY_PAGE: 'settings',
  EMBEDDED_PLAYER_KEY_PAGE: 'embedded',
  EMBEDDED_PLAYER_LTI_KEY_PAGE: 'embedded_lti',
  MANAGE_USERS_KEY_PAGE: 'manage_users',
  ERROR_404_KEY_PAGE: 'error404',
  HEALTH_KEY_PAGE: 'health',
  VERSION_KEY_PAGE: 'version',
  AUTHZ_LIST_KEY_PAGE: 'authzList',
  AUTHZ_LIST_LTI_KEY_PAGE: 'authzList',

  // MEDIA
  MEDIA: 'Media',
  NEW_MEDIA: 'New media',
  MY_UPLOADS: 'My uploads',
  PENDING_UPLOADS: 'Pending uploads',
  ADD_MEDIA: 'Add media',

  // EDIT MEDIA
  DETAILS: 'Details',
  SHARE: 'Share',
  ACCESS: 'Access',
  TRANSCRIPT: 'Transcript',
  UPLOAD_POSTER_HINT: 'Upload an image that represents your media',

  // UPLOADING MEDIA
  UPLOADING_MEDIA: 'uploadingMedia',
  BACK_TO_MEDIA: 'Go back to media',
  SUPPORTED_VIDEO: ['.mp4', '.mov', '.ogg', '.webm', '.mkv', '.mpeg4', '.wmv', '.flv', '.avi'],
  SUPPORTED_AUDIO: ['.mp3', '.wav', '.wma', '.aac'],
  RETRIES: 5,
  MB: 1000000,
  CHUNK_SIZE: 60000000,

  // COLLECTIONS
  COLLECTIONS: 'Collections',
  MY_COLLECTIONS: 'My collections',
  NEW_COLLECTION: 'New collection',
  OPEN_COLLECTION: 'Open collection',

  // NEW COLLECTION
  BACK_TO_COLLECTIONS: 'Go back to collections',
  CREATE_NEW_COLLECTION: 'Create a new collection',
  COLLECTION_TITLE: 'Collection title',
  DELETE_COLLECTION: 'Delete collection',
  BACK_TO_COLLECTION: 'Go back to collection',
  BACK_TO_NEW_COLLECTION: 'Go back to new collection',

  // PLAYLISTS
  PLAYLISTS: 'Playlists',
  MY_PLAYLISTS: 'My playlists',
  NEW_PLAYLIST: 'New playlist',
  OPEN_PLAYLIST: 'Open playlist',

  // NEW PLAYLIST
  BACK_TO_PLAYLISTS: 'Go back to playlists',
  BACK_TO_PLAYLIST: 'Go back to playlist',
  CREATE_NEW_PLAYLIST: 'Create a new playlist',
  PLAYLIST_TITLE: 'Playlist title',
  DELETE_PLAYLIST: 'Delete playlist',
  BACK_TO_NEW_PLAYLIST: 'Go back to new playlist',

  // SETTINGS
  SETTINGS: 'Settings',

  // MANAGE_USERS
  MANAGE_USERS: 'Manage Users',

  // ROLES
  PLATFORM_ADMIN: 'platformAdmin',
  SITE_ADMIN: 'siteAdmin',
  USER: 'user',

  // HELP
  HELP: 'Help',
  SITES_LIST: 'Sites list',
  SITES_ADMIN: 'Sites admin',
  SEND_FEEDBACK: 'Send Feedback',

  // GENERAL
  DOCUMENT_NAME: 'ASU',
  SITE: 'site',
  ACCESS_TOKEN: 'accessToken',
  SHARED_WITH_ME: 'Shared with me',
  SHARED_WITH_ME_PLAYLISTS: 'Shared with me playlists',
  SHARED_WITH_ME_COLLECTIONS: 'Shared with me collections',
  SAVE_CHANGES: 'Save changes',
  CANCEL: 'Cancel',
  EDIT: 'edit',
  PLAY: 'play',
  UPDATE: 'update',
  DELETE: 'delete',
  ENTER: 'enter',
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  EMBED: 'embed',
  CHANGE_MEDIA_OWNERSHIP: 'Change media ownership',
  DELETE_LINK: 'Delete link',
  MIN_WINDOW_WIDTH_SIZE_DESKTOP: 1126,
  UNEXPECTED_ERROR_MESSAGE_OOPS: 'Oops! Something went wrong!',
  UNEXPECTED_ERROR_MESSAGE_MEDIA_SORRY: "Sorry, we couldn't upload a media.",
  UNEXPECTED_ERROR_MESSAGE_HELP_US: 'Help us improve your experience by sending an error report.',
  UNSAVED_CHANGES: 'WARNING: Unsaved Changes',
  UNSAVED_CHANGES_TEXTS: [
    'There are unsaved changes on this page.',
    'If you continue, your changes will be lost.',
    'Do you want to continue?',
  ],
  CHANGE_MEDIA_OWNERSHIP_TEXTS: [
    'This action will make you no longer have access to the media.',
    'If you want to keep your access, share the media with yourself before changing ownership',
    'Are you sure you want to continue?',
  ],
  DELETE_LINK_TEXTS: [
    'This action will make this link not longer valid to access.',
    'Are you sure you want to continue?',
  ],
  WARNING_TITLE: 'WARNING: ',
  NOT_ABLE_TO_RECOVER_TEXT: 'Deleting this item is final, are you sure?',
  COPYRIGHT_TYPES: ['In copyright', 'No copyright', 'No known copyright'],
  REUSE_TYPES: [
    'Public Domain',
    'All Rights Reserved',
    'Attribution',
    'Attribution + ShareAlike',
    'Attribution - No Derivatives',
    'Attribution + NonCommercial',
    'Attribution + NonCommercial + ShareAlike',
    'Attribution + NonCommercial + No Derivatives',
  ],
};
