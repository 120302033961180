import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import Plyr from 'plyr-react';
import apiCalls from '../../../services/api-calls/all';
import { SUPPORTED_AUDIO } from '../../../utils/constants-utils';
import { errorLogger } from '../../../utils/generic-utils';

import 'plyr-react/dist/plyr.css';
import './_style.scss';

const { getMediaOnS3ById } = apiCalls();
const VIDEO = 'video';
const AUDIO = 'audio';

const MediaPlayer = ({ id, siteId, showTitle, onEnded }) => {
  const ref = useRef();
  const [media, setMedia] = useState();
  const [mediaType, setMediaType] = useState(VIDEO);
  useEffect(() => {
    if (id) searchMedia();
  }, [id]);

  useEffect(() => {
    if (ref.current) ref.current.plyr.on('ended', onEnded);
  });

  const searchMedia = async () => {
    try {
      const { data } = await getMediaOnS3ById({ fileId: id, siteId });
      const {
        videoInfo: { fileKey },
      } = data;
      const fileType = fileKey.slice(fileKey.indexOf('.'));
      setMediaType(SUPPORTED_AUDIO.includes(fileType) ? AUDIO : VIDEO);
      setMedia(data);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while searching Media url:', error });
    }
  };

  const mediaSource = () => ({
    type: mediaType,
    sources: [
      {
        src: media.url,
      },
    ],
    tracks: media.captionsUrl
      ? [
          {
            kind: 'captions',
            label: 'Captions',
            src: media.captionsUrl,
            default: false,
          },
        ]
      : [],
  });

  return media ? (
    <div className="MediaPlayerContainer">
      {showTitle && (
        <div className="MediaPlayerTitleContainer">
          <p className="Title">{media.videoInfo.title}</p>
        </div>
      )}
      <div className="MediaPlayerFlex">
        <div className="MediaPlayer">
          <Plyr source={mediaSource()} crossOrigin="anonymous" ref={ref} />
        </div>
      </div>
    </div>
  ) : (
    <Spin />
  );
};

MediaPlayer.propTypes = {
  id: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
  onEnded: PropTypes.func,
};

MediaPlayer.defaultProps = {
  id: undefined,
  showTitle: false,
  onEnded: () => {},
};

export default MediaPlayer;
