import React, { useState, useContext } from 'react';
import { VideoCameraAddOutlined } from '@ant-design/icons';
import MediaFeedContainer from '../../organisms/media-feed-container/media-feed-container';
import { MediaUploadContext } from '../../../services/providers/media-upload-context';
import GenericActions from '../../molecules/table-columns/generic-actions/generic-actions.table-columns';
import { useRedirect } from '../../router/redirect';
import { UserContext } from '../../../services/providers/user-context';
import { SiteContext } from '../../../services/providers/site-context';
import DeleteActionColumnContainer from '../../molecules/table-columns/delete-action/delete-action.table-columns';
import {
  myUploadsColumns,
  sharedWithMeColumns,
  pendingUploadsColumns,
} from '../../molecules/table-headers/media.table-header';
import {
  MEDIA_EDIT_URL,
  MEDIA_PLAYER_URL,
  MEDIA_UPLOAD_URL,
  MEDIA,
  MEDIA_URL,
  NEW_MEDIA,
  MY_UPLOADS,
  SHARED_WITH_ME,
  PENDING_UPLOADS,
  MB,
  CHUNK_SIZE,
  EDIT,
  PLAY,
} from '../../../utils/constants-utils';
import apiCalls from '../../../services/api-calls/all';
import {
  generateKeyFromString,
  divisionWithRoundedDecimals,
  divisionRoundedUp,
  Percentage,
} from '../../../utils/generic-utils';
import './_style.scss';

const processMediaInfo = ({ data }) =>
  data.Items.map(({ timeStamp, MediaId, ...rest }) => ({
    key: MediaId,
    created: timeStamp,
    ...rest,
  }));

export const uploadProgressCalc = ({
  updatedParts,
  uploadId,
  deleteMedia,
  MBUpdatedSize,
  MBSize,
  PercentageProgress,
  size,
}) => {
  // -1 value at updatedParts indicates a failed upload
  if (updatedParts === -1) {
    return <DeleteActionColumnContainer onDeleteClick={() => deleteMedia(uploadId)} />;
  }
  if (size < CHUNK_SIZE) {
    return 'uploading...';
  }
  return `${MBUpdatedSize} Mb / ${MBSize} Mb ${PercentageProgress}%`;
};

const { getMediaList, listMediasWithAccess } = apiCalls();

const MediaPage = () => {
  const [mediaSelected, setMediaSelected] = useState();
  const { user } = useContext(UserContext);
  const { site } = useContext(SiteContext);
  const { uploadingMedia, removeUploadedMedia } = useContext(MediaUploadContext);
  const { redirect, setUrlToRedirect } = useRedirect();

  const goToMediaPlay = ({ key }) => {
    setMediaSelected(key);
    setUrlToRedirect(MEDIA_PLAYER_URL);
  };
  const getMediaParameters = {
    ownerId: user.userId,
    siteId: site.SiteId,
  };
  const getSharedMediaParameters = {
    asuriteId: user.userId,
    siteId: site.SiteId,
  };
  const goToMediaEdit = ({ key }) => {
    setMediaSelected(key);
    setUrlToRedirect(MEDIA_EDIT_URL);
  };

  const goToNewMedia = () => {
    setUrlToRedirect(MEDIA_UPLOAD_URL);
  };

  const deleteMedia = (uploadId) => {
    removeUploadedMedia({ upload_id: uploadId });
  };

  const processPendingUploadInfo = (data) =>
    data.map((pendingUpload) => {
      const {
        upload_progress: { size, updatedParts },
        // eslint-disable-next-line camelcase
        upload_id,
      } = pendingUpload;
      const MBSize = divisionWithRoundedDecimals(size, MB);
      const rawMBUpdatedSize = (updatedParts * CHUNK_SIZE) / MB;
      const MBUpdatedSize = rawMBUpdatedSize > MBSize ? MBSize : rawMBUpdatedSize;
      const partsQuantity = divisionRoundedUp(size, CHUNK_SIZE);
      const PercentageProgress = Percentage(updatedParts, partsQuantity);
      return {
        ...pendingUpload,
        key: upload_id,
        upload_progress: uploadProgressCalc({
          updatedParts,
          uploadId: upload_id,
          deleteMedia,
          MBUpdatedSize,
          MBSize,
          PercentageProgress,
          size,
        }),
      };
    });

  const actions = [
    { key: EDIT, onClick: goToMediaEdit },
    { key: PLAY, onClick: goToMediaPlay },
  ];

  const tabs = [
    {
      name: MY_UPLOADS,
      key: generateKeyFromString(MY_UPLOADS),
      tableColumns: [...myUploadsColumns, GenericActions({ actions })],
      processEndpointResult: processMediaInfo,
      endpoint: getMediaList,
      editRowFunc: goToMediaEdit,
      playRowFunc: goToMediaPlay,
      endpointParameters: getMediaParameters,
      ariaLabel: 'My uploaded media list',
    },
    {
      name: SHARED_WITH_ME,
      key: generateKeyFromString(SHARED_WITH_ME),
      tableColumns: [...sharedWithMeColumns, GenericActions({ actions })],
      processEndpointResult: processMediaInfo,
      endpoint: listMediasWithAccess,
      editRowFunc: goToMediaEdit,
      playRowFunc: goToMediaPlay,
      endpointParameters: getSharedMediaParameters,
      ariaLabel: 'Shared with me media list',
    },
    {
      name: PENDING_UPLOADS,
      key: generateKeyFromString(PENDING_UPLOADS),
      tableColumns: pendingUploadsColumns,
      processEndpointResult: processPendingUploadInfo,
      endpoint: () => uploadingMedia,
      ariaLabel: 'My pending uploads list',
    },
  ];

  const headerInfo = {
    title: MEDIA,
    ButtonText: NEW_MEDIA,
    ButtonIcon: <VideoCameraAddOutlined />,
    ButtonAction: goToNewMedia,
  };

  return (
    <div className="MediaPageContainer" tabIndex={0} aria-label="Media list page">
      {mediaSelected ? redirect({ id: mediaSelected, returnUrl: MEDIA_URL }) : redirect()}
      <MediaFeedContainer tabs={tabs} headerInfo={headerInfo} />
    </div>
  );
};

export default MediaPage;
