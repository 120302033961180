import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiCalls from '../../../services/api-calls/all';
import MediaPlayer from '../media-player/media-player';
import GenericTable from '../../atoms/generic-table/generic-table';
import { errorLogger } from '../../../utils/generic-utils';
import { playlistPlayerColumns } from '../../molecules/table-headers/playlist-player.table-header';
import './_style.scss';

const { getPlaylistById } = apiCalls();

const PlaylistPlayer = ({ playlistId, siteId }) => {
  const [selectedMedia, setSelectedMedia] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [playlistTitle, setPlaylistTitle] = useState();
  const [dataSource, setDataSource] = useState();

  useEffect(() => {
    if (playlistId) getPlaylist();
  }, [playlistId]);

  const getPlaylist = async () => {
    try {
      const {
        data: {
          mediaItems: { Items },
          title,
        },
      } = await getPlaylistById({
        playlistId,
        siteId,
      });
      setPlaylistTitle(title);
      setSelectedMedia(Items[0]);
      setDataSource(Items);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while getting playlist:', error });
    }
  };

  useEffect(() => {
    if (dataSource) {
      setSelectedIndex(dataSource.indexOf(selectedMedia));
    }
  }, [selectedMedia]);

  const nextMedia = () => {
    const currentIndex = dataSource.indexOf(selectedMedia);
    if (currentIndex + 1 < dataSource.length) {
      setSelectedIndex(currentIndex + 1);
      setSelectedMedia(dataSource[currentIndex + 1]);
    }
  };

  return (
    <div className="PlayListsPlayerContainer">
      <div>
        <div className="PlayListsPlayer">
          {selectedMedia && (
            <MediaPlayer id={selectedMedia.MediaId} siteId={siteId} showTitle onEnded={nextMedia} />
          )}
        </div>
        <div className="PlayListsPlayerTitleContainer">
          <p className="Title">Playlist: {playlistTitle}</p>
          <GenericTable
            columns={playlistPlayerColumns}
            data={dataSource}
            onClick={(item) => setSelectedMedia(item)}
            selectedIndex={selectedIndex}
          />
        </div>
      </div>
    </div>
  );
};

PlaylistPlayer.propTypes = {
  playlistId: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
};

export default PlaylistPlayer;
