import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../services/providers/user-context';
import Header from '../organisms/header/header';
import SideMenu from '../organisms/side-menu/side-menu';
import ltiNoHeaderRoutes from './lti-no-header-routes.json';
import {
  LANDING_URL,
  MEDIA,
  COLLECTIONS,
  PLAYLISTS,
  HELP,
  SITES_LIST,
  SITES_ADMIN,
  SITES_ADMIN_URL,
  SITES_LIST_URL,
  MANAGE_USERS_URL,
  SETTINGS,
  PLATFORM_ADMIN,
  SITE_ADMIN,
} from '../../utils/constants-utils';
import './_style.scss';

const ADMIN_ROLES = [PLATFORM_ADMIN, SITE_ADMIN];
const commonMenuList = [MEDIA, COLLECTIONS, PLAYLISTS, HELP];
const siteMenuList = [SITES_LIST, SITES_ADMIN];
const siteMenuUrls = [SITES_ADMIN_URL, SITES_LIST_URL, MANAGE_USERS_URL];

const PrivateRoute = ({ component: Component, requireAuthentication, ...rest }) => {
  const { user } = useContext(UserContext);

  const menuList = ADMIN_ROLES.includes(user.userRole)
    ? [...commonMenuList, SETTINGS]
    : commonMenuList;

  const [menuItems, setMenuItems] = useState(menuList);
  useEffect(() => {
    if (siteMenuUrls.includes(window.location.pathname)) {
      setMenuItems(siteMenuList);
    } else {
      setMenuItems(menuList);
    }
  }, [window.location]);
  if (!requireAuthentication) {
    if (ltiNoHeaderRoutes.routes.includes(window.location.pathname)) {
      return (
        <div>
          <Header keyPage={rest.keyPage} />
          <div className="bodyContainer">
            <Route {...rest} render={(properties) => <Component {...properties} />} />
          </div>
        </div>
      );
    }
    return (
      <div>
        <Header keyPage={rest.keyPage} />
        <div className="bodyContainer">
          <SideMenu keyPage={rest.keyPage} items={menuItems} />
          <Route {...rest} render={(properties) => <Component {...properties} />} />
        </div>
      </div>
    );
  }
  return user.accessToken ? (
    <div>
      <Header keyPage={rest.keyPage} signed />
      <div className="bodyContainer">
        <SideMenu keyPage={rest.keyPage} items={menuItems} />
        <Route {...rest} render={(properties) => <Component {...properties} />} />
      </div>
    </div>
  ) : (
    <Redirect to={LANDING_URL} />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string,
  requireAuthentication: PropTypes.bool.isRequired,
};

PrivateRoute.defaultProps = {
  exact: false,
  path: undefined,
};

export default PrivateRoute;
