import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import GenericTabs from '../../atoms/generic-tabs/generic-tabs';
import { MediaUploadContext } from '../../../services/providers/media-upload-context';
import TabContent from '../tab-content/tab-content';
import { errorLogger } from '../../../utils/generic-utils';

import './_style.scss';

const TABLES_WITH_UPDATE = ['pending_uploads'];
const TABLES_WITH_POSSIBLE_UPDATE = ['my_uploads'];

const MediaFeed = ({ tabs, isLti }) => {
  const [selectedTab, setSelectedTab] = useState();
  const [offsetKey, setOffsetKey] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [dataSource, setDataSource] = useState();
  const [loading, setLoading] = useState();
  const [gridView, setGridView] = useState();
  const [nameFilter, setNameFilter] = useState();
  const [filterForm, setFilterForm] = useState();
  const [uploadingMediaLastLenght, setUploadingMediaLastLenght] = useState();
  const { uploadingMedia } = useContext(MediaUploadContext);

  useEffect(() => {
    if (selectedTab && TABLES_WITH_UPDATE.includes(selectedTab.key)) {
      setSelectedTab(tabs[tabIndex]);
    }
  }, [tabs]);

  useEffect(async () => {
    if (
      selectedTab &&
      TABLES_WITH_POSSIBLE_UPDATE.includes(selectedTab.key) &&
      uploadingMedia.length < uploadingMediaLastLenght
    ) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      getTableDataSource(selectedTab, undefined, true);
    }
    setUploadingMediaLastLenght(uploadingMedia.length);
  }, [uploadingMedia]);

  useEffect(() => {
    if (selectedTab) getTableDataSource(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (tabs) {
      setSelectedTab(tabs[tabIndex]);
    }
  }, [tabIndex]);

  const getTableDataSource = async (
    { endpoint, processEndpointResult, endpointParameters, key },
    newFilter,
    force
  ) => {
    if (offsetKey === -1 && !force) return;
    let olderDataSource = dataSource;
    if (key === 'pending_uploads') {
      setDataSource([]);
      olderDataSource = undefined;
    }
    let newEndpointParameters =
      offsetKey && offsetKey !== -1
        ? { ...endpointParameters, offsetKey: JSON.stringify(offsetKey), nameFilter }
        : { ...endpointParameters, nameFilter };
    try {
      setLoading(true);
      if (newFilter || force) {
        newEndpointParameters = { ...endpointParameters, nameFilter: newFilter };
        olderDataSource = undefined;
      }
      const result =
        endpoint instanceof Function ? await endpoint(newEndpointParameters) : endpoint;
      const lastkey = processLastKey(result);
      const processedResult = processEndpointResult(result);
      setOffsetKey(lastkey);
      setDataSource(olderDataSource ? [...olderDataSource, ...processedResult] : processedResult);
    } catch (error) {
      errorLogger({ loggedMessage: 'Media-Feed: Error while getting Table DataSource:', error });
    }
    setLoading(false);
  };

  const processLastKey = (result = {}) => {
    if (!result.data) return undefined;
    return result.data.LastEvaluatedKey ? result.data.LastEvaluatedKey : -1;
  };

  const endScrollFunc = () => {
    if (selectedTab.key === 'pending_uploads') return;
    getTableDataSource(selectedTab);
  };

  const filterByField = (title, form) => {
    setFilterForm(form);
    // eslint-disable-next-line eqeqeq
    if (title == nameFilter) {
      setNewFilter();
      form.resetFields();
    } else setNewFilter(title);
  };

  const setNewFilter = (filter) => {
    setNameFilter(filter);
    getTableDataSource(selectedTab, filter, true);
  };

  return (
    <div className="MediaFeed">
      {selectedTab && (
        <GenericTabs
          tableLoading={loading}
          onChange={(key) => {
            if (filterForm) filterForm.resetFields();
            setNameFilter();
            setOffsetKey();
            setDataSource([]);
            setTabIndex(key);
          }}
          panels={tabs.map(({ name }, key) => ({
            name,
            key,
          }))}
        >
          <TabContent
            dataSource={dataSource}
            loading={loading}
            selectedTab={selectedTab}
            gridView={gridView}
            changeView={(value) => setGridView(value)}
            isLti={isLti}
            endScrollFunc={endScrollFunc}
            filterPlaceholder="search by Title"
            filterByField={filterByField}
            isFiltered={nameFilter}
          />
        </GenericTabs>
      )}
    </div>
  );
};

MediaFeed.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      endpoint: PropTypes.func,
      processEndpointResult: PropTypes.func,
      tableColumns: PropTypes.arrayOf(PropTypes.object),
      selectRowFunc: PropTypes.func,
      name: PropTypes.string,
      key: PropTypes.string,
    })
  ).isRequired,
  isLti: PropTypes.bool,
};

MediaFeed.defaultProps = { isLti: false };

export default MediaFeed;
