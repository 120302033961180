import React, { useState, useEffect } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import apiCalls from '../../../services/api-calls/all';
import { errorLogger } from '../../../utils/generic-utils';
import './_style.scss';

const { getHealthCheck } = apiCalls();

const STATUSES = ['serverlessStatus', 'dynamoStatus', 'S3Status'];
const SERVICES_NAME_BY_STATUS_KEY = {
  serverlessStatus: 'Serverless',
  dynamoStatus: 'Dynamo',
  S3Status: 'S3',
};

const HealthPage = () => {
  const [loading, setLoading] = useState();
  const [status, setStatus] = useState({});

  useEffect(async () => {
    getHealthStatusCheck();
  }, []);

  const getHealthStatusCheck = async () => {
    setLoading(true);
    try {
      const {
        data: { accessToDynamo, accessToS3 },
      } = await getHealthCheck();
      setStatus({ serverlessStatus: true, dynamoStatus: accessToDynamo, S3Status: accessToS3 });
    } catch (error) {
      setStatus({ serverlessStatus: false, dynamoStatus: false, S3Status: false });
      errorLogger({
        loggedMessage: 'Error while getting health status check:',
        error,
        notifyUser: false,
      });
    }
    setLoading(false);
  };

  const IconRender = (loadingIcon, statusIcon) => {
    if (loadingIcon) return <LoadingOutlined style={{ fontSize: '20px' }} />;
    return statusIcon ? (
      <CheckCircleOutlined style={{ fontSize: '20px', color: 'Green' }} />
    ) : (
      <CloseCircleOutlined style={{ fontSize: '20px', color: 'Red' }} />
    );
  };

  const servicesStatuses = STATUSES.map((statusKey) => (
    <div className={`service ${statusKey}`} key={statusKey}>
      {`${SERVICES_NAME_BY_STATUS_KEY[statusKey]}:`}
      {IconRender(loading, status[statusKey])}
    </div>
  ));

  return (
    <div className="HealthPageContainer">
      <div>
        <p className="title">Services health statuses:</p>
      </div>
      <div className="StatusInfoContainer">{servicesStatuses}</div>
    </div>
  );
};

export default HealthPage;
