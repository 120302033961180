import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import PlaylistPlayer from '../../organisms/playlist-player/playlist-player';
import apiCalls from '../../../services/api-calls/all';
import { errorLogger } from '../../../utils/generic-utils';
import './_style.scss';

const { getSharedLink } = apiCalls();

const PlaylistPlayerPage = () => {
  const [playlistId, setPlaylistId] = useState();
  const [siteId, setSiteId] = useState();
  const [loading, setLoading] = useState();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setSiteId(newsite);
    const newsite = params.get('siteId');
    const shareId = params.get('id');
    const isPrivate = params.get('isPrivate') === 'true';
    setSiteId(newsite);
    getPlaylistBySharedlink(shareId, isPrivate, newsite);
  }, []);
  const getPlaylistBySharedlink = async (shareId, isPrivate, newSiteId) => {
    setLoading(true);
    try {
      const {
        data: {
          message: { Items },
        },
      } = await getSharedLink({
        shareId,
        siteId: newSiteId,
        isPrivate,
      });
      if (Items.length) {
        setPlaylistId(Items[0].BundleId);
      }
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while geting users list:', error });
    }
    setLoading(false);
  };
  return (
    <div className="PlayListsPlayerPage">
      {loading ? <Spin /> : <PlaylistPlayer playlistId={playlistId} siteId={siteId} />}
    </div>
  );
};

export default PlaylistPlayerPage;
