import { React, useContext } from 'react';
import PropTypes from 'prop-types';
import './_style.scss';
import ProfileInfo from '../../atoms/profile-info/profile-info';
import NavMenu from '../../atoms/nav-menu/nav-menu';
import { MEDIA_LTI_KEY_PAGE, MEDIA_UPLOAD_LTI_KEY_PAGE } from '../../../utils/constants-utils';
import { generateKeyFromString } from '../../../utils/generic-utils';
import { UserContext } from '../../../services/providers/user-context';
import { SiteContext } from '../../../services/providers/site-context';

const PAGES_WITHOUT_SIDE_MENU = [MEDIA_LTI_KEY_PAGE, MEDIA_UPLOAD_LTI_KEY_PAGE];

const SideMenu = ({ keyPage, items }) => {
  const processedItems = items.map((item) => ({ title: item, key: generateKeyFromString(item) }));
  const { user } = useContext(UserContext);
  const { site } = useContext(SiteContext);
  return PAGES_WITHOUT_SIDE_MENU.includes(keyPage) ? (
    <div />
  ) : (
    <div className="menu">
      <div className="ProfileContainer">
        <ProfileInfo name={user.userId} siteRole={user.userRole} site={site.SiteName} />
      </div>
      <div className="NavMenuContainer">
        <NavMenu items={processedItems} keyPage={keyPage} />
      </div>
    </div>
  );
};

SideMenu.propTypes = {
  keyPage: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SideMenu.defaultProps = {};

export default SideMenu;
