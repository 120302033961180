import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Radio, Space } from 'antd';
import { FileTextOutlined, DownloadOutlined } from '@ant-design/icons';
import apiCalls from '../../../../services/api-calls/all';
import AssetDragger from '../../../atoms/asset-dragger/asset-dragger';
import { errorLogger } from '../../../../utils/generic-utils';
import './_style.scss';

const { Title } = Typography;
const { getAssetUploadUrl, uploadFilesToS3 } = apiCalls();

const MediaTranscript = ({ currentMedia, siteId, updateCurrentMedia }) => {
  const [mediaTranscriptions, setMediaTranscriptions] = useState([]);
  const [draggerDisabled, setDraggerDisabled] = useState(false);
  const [defaultTranscriptionIndex, setDefaultTranscriptionIndex] = useState(0);
  useEffect(() => {
    if (currentMedia.captions && currentMedia.captions.length) {
      setMediaTranscriptions(currentMedia.captions);
    }
  }, [currentMedia.captions]);
  useEffect(() => {
    if (mediaTranscriptions && mediaTranscriptions.length) {
      mediaTranscriptions.forEach((item, index) => {
        if (item.isDefault) setDefaultTranscriptionIndex(index);
      });
      updateCurrentMedia({ ...currentMedia, captions: mediaTranscriptions });
    }
  }, [mediaTranscriptions]);
  // Everytime a new transcription is uploaded, we store it on s3 and update media record on dynamo.
  const onTranscriptDrop = async ({ file }) => {
    setDraggerDisabled(true);
    try {
      const { name } = file;
      const mediaFileKey = currentMedia.fileKey;
      const mediaFilePath = mediaFileKey.slice(0, mediaFileKey.indexOf('/'));
      const {
        data: { url, fields },
      } = await getAssetUploadUrl({
        filePath: `${mediaFilePath}/${name}`,
        siteId,
      });
      const formData = new FormData();
      Object.entries(fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
      formData.append('file', file);
      await uploadFilesToS3(url, formData);
      const newMediaTranscriptions = [
        ...mediaTranscriptions,
        { fileName: name, isDefault: !mediaTranscriptions.length },
      ];
      setMediaTranscriptions(newMediaTranscriptions);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while uploading transcript:', error });
    }
    setDraggerDisabled(false);
  };

  const mediaTranscriptionsTitles = mediaTranscriptions.map((item) => {
    console.log('item:', item);
    return (
      <div key={item.fileName}>
        <span>{item.fileName}</span>
        {item.captionsUrl && (
          <a href={item.captionsUrl} target="_blank" rel="noreferrer">
            <DownloadOutlined />
          </a>
        )}
      </div>
    );
  });

  const handleDefaultTranscriptionChange = (e) => {
    setDefaultTranscriptionIndex(e.target.value);
    const newMediaTranscriptions = mediaTranscriptions.map((item, index) => {
      if (index === e.target.value) {
        return { fileName: item.fileName, isDefault: true, captionsUrl: item.captionsUrl };
      }
      return { fileName: item.fileName, isDefault: false, captionsUrl: item.captionsUrl };
    });
    setMediaTranscriptions(newMediaTranscriptions);
  };
  return (
    <div className="MediaTranscriptEditorContainer">
      <div className="MediaTranscriptEditorField" key="MediaTranscriptionDraggerField">
        <Title level={5}>Upload transcript</Title>
        <AssetDragger
          disabled={draggerDisabled}
          onChange={onTranscriptDrop}
          text="Upload a transcript for your video"
          icon={<FileTextOutlined />}
        />
      </div>
      {mediaTranscriptions.length > 0 && (
        <div className="MediaTranscriptEditorField" key="MediaTranscriptionDownloadField">
          <Title level={5}>Download a transcript</Title>
          {mediaTranscriptionsTitles}
        </div>
      )}
      {mediaTranscriptions.length > 1 && (
        <div className="MediaTranscriptEditorField" key="MediaTranscriptionDefaultField">
          <Title level={5}>Select default transcript</Title>
          <Radio.Group
            onChange={handleDefaultTranscriptionChange}
            value={defaultTranscriptionIndex}
            data-testid="radio-group"
          >
            <Space direction="vertical">
              {mediaTranscriptions.map((item, index) => (
                <Radio value={index} data-testid={`radio-${index}`} key={item.fileName}>
                  {item.fileName}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
      )}
    </div>
  );
};

MediaTranscript.propTypes = {
  currentMedia: PropTypes.shape(PropTypes.object.isRequired),
  siteId: PropTypes.string.isRequired,
  updateCurrentMedia: PropTypes.func.isRequired,
};

MediaTranscript.defaultProps = {
  currentMedia: {},
};

export default MediaTranscript;
