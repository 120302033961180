import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Select, Divider, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import GenericTable from '../../atoms/generic-table/generic-table';
import GenericActions from '../table-columns/generic-actions/generic-actions.table-columns';
import { ShareWithUsersColumns } from '../table-headers/share-with-users.table-header';
import {
  DELETE,
  MEDIA_URL,
  CHANGE_MEDIA_OWNERSHIP,
  WARNING_TITLE,
} from '../../../utils/constants-utils';
import { CHANGE_MEDIA_OWNERSHIP_WARNING } from '../../../utils/constants-content-messages';
import { errorLogger } from '../../../utils/generic-utils';
import apiCalls from '../../../services/api-calls/all';
import { UserContext } from '../../../services/providers/user-context';
import { useRedirect } from '../../router/redirect';
import { SiteContext } from '../../../services/providers/site-context';
import GenericConfirmModal from '../../atoms/generic-confirm-modal/generic-confirm-modal';
import { EditModeContext } from '../../../services/providers/edit-mode-context';
import './_style.scss';

const { getUsersSiteList } = apiCalls();

const { Option } = Select;

const MediaShare = ({ shareAccessEndpoints }) => {
  const [dataSource, setDataSource] = useState([]);
  const [tableLoading, setTableLoading] = useState();
  const [siteUsers, setSiteUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [availableOwners, setAvailableOwners] = useState([]);
  const [newOwner, setNewOwner] = useState();
  const [changeOwnerModalVisible, setChangeOwnerModalVisible] = useState();
  const { user } = useContext(UserContext);
  const {
    site: { SiteId },
  } = useContext(SiteContext);
  const { editMode } = useContext(EditModeContext);
  const { redirect, setUrlToRedirect } = useRedirect();
  const shareWithFormRef = useRef();
  const ownershipFormRef = useRef();

  const usersSiteList = async () => {
    try {
      const { data } = await getUsersSiteList({
        queryAttribute: 'SiteId',
        queryValue: SiteId,
      });
      const processedData = processUsersList(data);
      setSiteUsers(processedData);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while geting site users list:', error });
    }
  };

  const usersWithAccessList = async () => {
    setTableLoading(true);
    try {
      const { data } = await shareAccessEndpoints.get();
      const processedData = processUsersWithAccessList(data);
      setDataSource(processedData);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while geting table users list:', error });
    }
    setTableLoading(false);
  };

  useEffect(async () => {
    await usersWithAccessList();
    await usersSiteList();
  }, []);

  useEffect(() => {
    const siteUsersList = siteUsers.map(({ userid }) => userid);
    const usersWithAccess = dataSource.map(({ userid }) => userid);
    const availableUsersList = siteUsersList.filter(
      (username) => !usersWithAccess.includes(username)
    );
    const availableOwnersList = siteUsersList.filter((username) => username !== user.userId);
    setAvailableUsers(availableUsersList);
    setAvailableOwners(availableOwnersList);
  }, [siteUsers, dataSource]);

  const removeShareWithUser = async ({ shareId }) => {
    setTableLoading(true);
    try {
      await shareAccessEndpoints.remove(shareId);
      await usersWithAccessList();
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while removing share with user:', error });
      setTableLoading(false);
    }
  };

  const shareWithUser = async ({ UserId }) => {
    setTableLoading(true);
    try {
      await shareAccessEndpoints.add(UserId);
      shareWithFormRef.current.setFieldsValue({ UserId: undefined });
      await usersWithAccessList();
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while sharing with user:', error });
      setTableLoading(false);
    }
  };

  const showOwnershipChangeWarning = ({ UserId }) => {
    setChangeOwnerModalVisible(true);
    setNewOwner(UserId);
  };

  const cancelOwnershipChange = () => {
    setChangeOwnerModalVisible(false);
    setNewOwner();
  };

  const postChangeOwnership = async () => {
    setChangeOwnerModalVisible(true);
    try {
      await shareAccessEndpoints.ownerChange(newOwner);
      setUrlToRedirect(MEDIA_URL);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while changing ownership:', error });
    }
  };

  const processUsersList = ({ Items }) =>
    Items.map((item) => ({
      authorizationId: item.AuthorizationId.S,
      userid: item.AsuriteId.S,
    }));

  const processUsersWithAccessList = ({ Items }) =>
    Items.map((item) => ({
      shareId: item.ShareId,
      userid: item.SharedWith,
    }));

  const actions = [
    {
      key: DELETE,
      onClick: removeShareWithUser,
    },
  ];

  const tableColumns = [...ShareWithUsersColumns, GenericActions({ actions })];

  const optionsByList = (list) =>
    list.map((userId) => (
      <Option key={userId} value={userId}>
        {userId}
      </Option>
    ));

  return (
    <div className="MediaShareContainer">
      {redirect()}
      <GenericConfirmModal
        title={`${WARNING_TITLE}${CHANGE_MEDIA_OWNERSHIP}?`}
        content={CHANGE_MEDIA_OWNERSHIP_WARNING}
        isModalVisible={changeOwnerModalVisible}
        handleOk={postChangeOwnership}
        handleCancel={cancelOwnershipChange}
      />
      <div className="shareWithUsersHeader">
        <p className="Title">Users with shared access to edit:</p>
      </div>
      <div className="shareWithUsersBody">
        <div className="UsersTable">
          <GenericTable columns={tableColumns} data={dataSource} loading={tableLoading} />
        </div>
        <Divider />
        <div className="shareWithUser">
          <p className="Title">Share access with new user:</p>
          <Form
            ref={shareWithFormRef}
            name="shareWithUserForm"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            onFinish={shareWithUser}
            autoComplete="off"
          >
            <Form.Item
              label="User"
              name="UserId"
              labelAlign="left"
              rules={[{ required: true, message: 'UserId is required' }]}
            >
              <Select
                showSearch
                style={{ width: '50%' }}
                placeholder="Select an userId to share"
                optionFilterProp="children"
                filterOption={
                  (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              >
                {optionsByList(availableUsers)}
              </Select>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Share with user
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Divider />
        {shareAccessEndpoints.ownerChange && (
          <div className="changeOwnerShip">
            <p className="Title">
              {editMode && (
                <Tooltip
                  className="tooltip"
                  title="Ownership change only available when there are no unsaved changes"
                >
                  <InfoCircleOutlined style={{ fontSize: '16px', color: 'Red' }} />
                </Tooltip>
              )}
              Change ownership:
            </p>
            <Form
              ref={ownershipFormRef}
              name="changeOwnerShipForm"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              onFinish={showOwnershipChangeWarning}
              autoComplete="off"
            >
              <Form.Item
                label="New owner"
                name="UserId"
                labelAlign="left"
                rules={[{ required: true, message: 'UserId is required' }]}
              >
                <Select
                  showSearch
                  style={{ width: '50%' }}
                  placeholder="Select new owner"
                  optionFilterProp="children"
                  disabled={editMode}
                  filterOption={
                    (input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  {optionsByList(availableOwners)}
                </Select>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button type="primary" htmlType="submit" disabled={editMode}>
                  Change ownership
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

MediaShare.propTypes = {
  shareAccessEndpoints: PropTypes.shape({
    get: PropTypes.func,
    add: PropTypes.func,
    remove: PropTypes.func,
    ownerChange: PropTypes.func,
  }).isRequired,
};

export default MediaShare;
