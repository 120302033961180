import { get } from 'lodash';
import { addQueryString } from '../../utils/urls-utils';
import {
  UNEXPECTED_ERROR,
  API_ERROR_401,
  API_ERROR_403,
  API_ERROR_500,
  STATUS_401,
  STATUS_403,
  STATUS_500,
} from './messages.constants.json';

const makeGetRequest = (httpClient) => (url, parameters, queryParameters) => {
  const completeUrl = addQueryString(url, queryParameters);
  return httpClient.get(completeUrl, {
    params: parameters,
  });
};

const makePostRequest =
  (httpClient) =>
  (url, bodyParameters = {}, queryParameters) => {
    const completeUrl = addQueryString(url, queryParameters);
    return httpClient.post(completeUrl, { ...bodyParameters });
  };

const makePostFileRequest = (httpClient) => (url, file, queryParameters) => {
  const completeUrl = addQueryString(url, queryParameters);
  return httpClient.post(completeUrl, file);
};

const makePatchRequest =
  (httpClient) =>
  (url, bodyParameters = {}, queryParameters) => {
    const completeUrl = addQueryString(url, queryParameters);
    return httpClient.patch(completeUrl, { ...bodyParameters });
  };

export default (client) => ({
  makeGetRequest: makeGetRequest(client),
  makePostRequest: makePostRequest(client),
  makePatchRequest: makePatchRequest(client),
  makePostFileRequest: makePostFileRequest(client),
});

export const processederrorLogger = (error, messageText) => {
  const status = get(error, 'response.status');
  if (!status) return UNEXPECTED_ERROR;
  switch (status) {
    case STATUS_401:
      return API_ERROR_401;
    case STATUS_403:
      return API_ERROR_403;
    case STATUS_500:
      return API_ERROR_500;
    default:
      return messageText || UNEXPECTED_ERROR;
  }
};
