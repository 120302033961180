import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Select } from 'antd';
import GenericTable from '../../atoms/generic-table/generic-table';
import GenericActions from '../../molecules/table-columns/generic-actions/generic-actions.table-columns';
import { ManageUsersColumns } from '../../molecules/table-headers/manage-users.table-header';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import {
  DELETE,
  SITE_ADMIN,
  USER,
  MANAGE_USERS,
  PLATFORM_ADMIN,
} from '../../../utils/constants-utils';
import { errorLogger } from '../../../utils/generic-utils';
import apiCalls from '../../../services/api-calls/all';
import './_style.scss';

const { deleteUser, putUser, getUsersSiteList } = apiCalls();

const { Option } = Select;

const SUPER_ADMIN_ROLES = [PLATFORM_ADMIN];

const ManageUsersContianer = ({ currentSite, userRole, returnFunc, returnText }) => {
  const [dataSource, setDataSource] = useState();
  const [tableLoading, setTableLoading] = useState();

  const evaluateRole = () => SUPER_ADMIN_ROLES.includes(userRole);

  const userOption = <Option value={USER}>User</Option>;
  const siteAdminOption = <Option value={SITE_ADMIN}>Site Admin</Option>;

  const options = evaluateRole() ? [userOption, siteAdminOption] : [userOption];

  const usersSiteList = async () => {
    setTableLoading(true);
    try {
      const { data } = await getUsersSiteList({
        queryAttribute: 'SiteId',
        queryValue: currentSite.id,
      });
      const processedData = processUsersList(data);
      setDataSource(processedData);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while geting users list:', error });
    }
    setTableLoading(false);
  };

  useEffect(() => {
    if (currentSite) usersSiteList();
  }, [currentSite]);

  const postDeleteUser = async ({ userid, authorizationId }) => {
    setTableLoading(true);
    try {
      const deleteItems = [
        {
          AsuriteId: {
            S: userid,
          },
          AuthorizationId: {
            S: authorizationId,
          },
        },
      ];
      await deleteUser({ Items: deleteItems, SiteId: currentSite.id });
      await usersSiteList();
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while removing user:', error });
      setTableLoading(false);
    }
  };

  const updateUserRole = async (authorizationId, userId, role) => {
    setTableLoading(true);
    try {
      await putUser({
        authorization_id: authorizationId,
        asurite_id: userId,
        platform_role: role,
        site_id: currentSite.id,
      });
      await usersSiteList();
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while updating user role:', error });
      setTableLoading(false);
    }
  };

  const postAddUser = async ({ UserId, role }) => {
    setTableLoading(true);
    try {
      await putUser({ asurite_id: UserId, platform_role: role, site_id: currentSite.id });
      await usersSiteList();
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while adding user:', error });
      setTableLoading(false);
    }
  };

  const processUsersList = (data) =>
    data.Items.map((item) => ({
      authorizationId: item.AuthorizationId.S,
      userid: item.AsuriteId.S,
      role: evaluateRole() ? (
        <Select
          style={{ width: 120 }}
          defaultValue={item.PlatformRole.S}
          value={item.PlatformRole.S}
          onChange={(value) => updateUserRole(item.AuthorizationId.S, item.AsuriteId.S, value)}
          disabled={!evaluateRole()}
        >
          {options}
        </Select>
      ) : (
        item.PlatformRole.S
      ),
    }));

  const actions = [
    {
      key: DELETE,
      onClick: postDeleteUser,
      showFunc: (value) => (evaluateRole() ? true : value !== SITE_ADMIN),
      showField: 'role',
    },
  ];

  const tableColumns = [...ManageUsersColumns, GenericActions({ actions })];

  return (
    <div className="ManageUsersContainer">
      {currentSite && (
        <div className="ManageUsersHeader">
          <p className="Title">{`${MANAGE_USERS}: ${currentSite.name}`}</p>
          {evaluateRole() && (
            <ButtonPrimary
              size="medium"
              id="manageUsersBackButton"
              onClick={returnFunc}
              theme="round"
              text={returnText}
            />
          )}
        </div>
      )}
      {dataSource && (
        <div className="ManageUsersBody">
          <div className="UsersTable">
            <GenericTable columns={tableColumns} data={dataSource} loading={tableLoading} />
          </div>
          <div className="addUserForm">
            <Form
              name="addUsersform"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ role: 'user' }}
              onFinish={postAddUser}
              autoComplete="off"
            >
              <Form.Item
                label="UserId"
                name="UserId"
                labelAlign="left"
                rules={[{ required: true, message: 'UserId is required' }]}
              >
                <Input style={{ width: '30%' }} />
              </Form.Item>
              <Form.Item
                name="role"
                label="Role"
                labelAlign="left"
                rules={[{ required: true, message: 'Role is required' }]}
              >
                <Select style={{ width: '30%' }}>{options}</Select>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Add User
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

ManageUsersContianer.propTypes = {
  currentSite: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  returnFunc: PropTypes.func,
  returnText: PropTypes.string,
};

ManageUsersContianer.defaultProps = {
  returnFunc: () => {},
  returnText: '',
};

export default ManageUsersContianer;
