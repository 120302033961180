import axios from 'axios';
import axiosInherit from 'axios-inherit';
import { HEADERS } from './base';

const configureAxios = ({ onError, onResponse }) => {
  const instance = axiosInherit(axios).create({
    timeout: 18000000,
    responseType: 'json',
    headers: {
      [HEADERS.CONTENT_TYPE]: HEADERS.MULTIPART,
    },
  });
  instance.interceptors.response.use(
    onResponse || ((response) => response),
    onError ||
      ((error) => {
        throw error;
      })
  );
  return instance;
};

export default configureAxios;
