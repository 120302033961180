import axios from 'axios';
import axiosInherit from 'axios-inherit';

const configureAxios = ({ onError, onResponse }) => {
  const instance = axiosInherit(axios).create({
    timeout: 18000000,
  });
  instance.interceptors.response.use(
    onResponse || ((response) => response),
    onError ||
      ((error) => {
        throw error;
      })
  );
  return instance;
};

export default configureAxios;
