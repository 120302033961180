import axios from 'axios';
import jwt from 'jsonwebtoken';
import config from './sso-config';
import { errorLogger } from '../utils/generic-utils';
import nonSsoRoutes from './publicRoutes.json';

const redirectUri = process.env.REACT_APP_redirectUri;
const clientId = process.env.REACT_APP_sso_clientId;
// all config items come from authn/sso-config.js file

function authorize() {
  // build URL to send to sso endpoint to retrieve authn code
  const authUrl =
    `${getBaseApiUrl(true)}?` +
    `code_challenge_method=${config.challengeMethod}&` +
    `code_challenge=${config.codeVerifier}&` +
    `client_id=${clientId}&` +
    `redirect_uri=${redirectUri}&` +
    `grant_type=${config.grantType}&` +
    `code_verifier=${config.codeVerifier}&` +
    `response_type=${config.responseType ? config.responseType : 'authorization_code'}`;

  return authUrl;
}

async function fetchFromLocalStorage(key) {
  // async wrapper to pull item from local storage
  try {
    return await localStorage.getItem(key);
  } catch (error) {
    return {};
  }
}

async function fetchAccessTokenFromRefresh(refreshToken) {
  // get new access token from refresh
  const uri = config.refreshTokenURL;
  const response = await postUrlEncoded(uri, refreshToken, 'refresh_token');
  return response;
}

async function getIdTokenFromAuth(payload) {
  // send the authn code to endpoint to get access token.
  const uri = `${getBaseApiUrl(true)}`;
  const response = await postUrlEncoded(uri, payload.code, 'code');
  return response;
}

async function logout() {
  // logout by removing userProfile from local storage.
  // AuthnCAS component will see this and redirect user to ASUrite sso login page
  try {
    await localStorage.removeItem('userProfile');
  } catch (error) {
    errorLogger({ loggedMessage: 'Error in logout method of api.js: ', error });
  }
}

async function validateAccessToken(accessToken) {
  // use jwt library to validate our access token.
  const { publicKey } = config;
  jwt.decode(accessToken, { complete: true });
  const result = jwt.verify(accessToken, publicKey);
  return result;
}

async function saveUser(user) {
  await localStorage.setItem('userProfile', JSON.stringify(user));
}

function getBaseApiUrl(useAuthUrl) {
  return useAuthUrl
    ? config.authURL // base API URL for auth things like the flow orchestration service
    : config.API_URI; // base API URL for non-auth things
}

function parseHash() {
  return window.location.hash
    .replace('#', '')
    .split('&')

    .reduce(
      (prev, item) => ({ [item.split('=')[0]]: decodeURIComponent(item.split('=')[1]), ...prev }),
      {}
    );
}

const generateRandomValue = () => {
  const crypto = window.crypto || window.msCrypto;
  const D = new Uint32Array(2);
  crypto.getRandomValues(D);
  return D[0].toString(36);
};

async function postUrlEncoded(urlSSOEndpoint, authCode, requestType) {
  // ASU sso endpoint requires a URL encoded Post.
  // ASU client ID and Secret, defined by them.
  const params = {
    client_id: clientId,
    client_secret: config.clientSecret,
  };
  if (requestType === 'code') {
    // we are regquesting new token from Auth Code
    params.code_verifier = config.codeVerifier;
    params.code = authCode;
    params.grant_type = config.grantType;
    params.redirect_uri = redirectUri;
  } else {
    // we are making a refresh token request
    params.refresh_token = authCode;
    params.grant_type = 'refresh_token';
  }
  // build all the params items into url encoded format for the data field
  const data = Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data,
    url: urlSSOEndpoint,
  };
  // make the POST
  const response = axios
    .post(options.url, options.data, options)
    .then((responseData) => responseData.data)
    .catch((error) => {
      errorLogger({ error, notifyUser: false });
    });

  return response; // return the Axios promise so it can be awaited
}

function isPublicRoute(fullUrl) {
  // url: string - example:  'https://example.com/testing'

  // strip off any querystring params
  const url = fullUrl.split(/[?#]/)[0];

  for (let i = 0; i < nonSsoRoutes.publicRoutes.length; i++) {
    const route = nonSsoRoutes.publicRoutes[i];
    if (url.endsWith(route) || url.slice(0, -1).endsWith(route) || (url + '/').endsWith(route)) {
      return true;
    }
  }
  return false;
}

export default authorize;

export {
  authorize,
  generateRandomValue,
  parseHash,
  getIdTokenFromAuth,
  saveUser,
  fetchFromLocalStorage,
  fetchAccessTokenFromRefresh,
  validateAccessToken,
  logout,
  postUrlEncoded,
  isPublicRoute,
};
