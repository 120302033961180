import axios from 'axios';

const envServerlessApi = process.env.REACT_APP_serverlessApi;
const envCreateSiteApi = process.env.REACT_APP_createSiteApi;
const envDeactivateSiteApi = process.env.REACT_APP_deactivateSiteApi;
const envUpdateSiteApi = process.env.REACT_APP_updateSiteApi;
const envReactivateSiteApi = process.env.REACT_APP_reactivateSiteApi;
const envInitializationSiteApi = process.env.REACT_APP_initializeSiteApi;
const envAuthorizationPutApi = process.env.REACT_APP_authorizationPutApi;
const envAuthorizationQueryApi = process.env.REACT_APP_authorizationQueryApi;
const envAuthorizationDeleteApi = process.env.REACT_APP_authorizationDeleteApi;

const apisUrls = [
  envServerlessApi,
  envCreateSiteApi,
  envDeactivateSiteApi,
  envUpdateSiteApi,
  envReactivateSiteApi,
  envInitializationSiteApi,
  envAuthorizationPutApi,
  envAuthorizationQueryApi,
  envAuthorizationDeleteApi,
];

export const jwtInterceptor = (accessToken) => {
  axios.interceptors.request.use((request) => {
    // add auth header with jwt if account is logged in and request is to the api url
    const isApiUrl = apisUrls.map((apiRoute) => request.url.startsWith(apiRoute)).find((e) => !!e);

    if (isApiUrl) {
      // eslint-disable-next-line no-param-reassign
      request.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    return request;
  });
};
