import client from './http-client';
import clientMultipart from './http-client-multipart';
import serverlessApi from './serverless-api.api-calls';
import files from './file.api-calls';
import serverlessApiMocks from './mocks/serverless-api.api-calls.mocks';
import filesMocks from './mocks/file.api-calls.mocks';

const defaultClient = client({});
const defaultClientMultipart = clientMultipart({});

export default () =>
  process.env.REACT_APP_CUSTOM_ENV === 'mocked'
    ? { ...serverlessApiMocks(), ...filesMocks() }
    : { ...serverlessApi(defaultClient), ...files(defaultClientMultipart) };
