import React, { useState, useEffect } from 'react';
import PlaylistPlayer from '../../organisms/playlist-player/playlist-player';
import './_style.scss';

const PlaylistPlayerPage = () => {
  const [playlistId, setPlaylistId] = useState();
  const [siteId, setSiteId] = useState();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setPlaylistId(params.get('id'));
    setSiteId(params.get('siteId'));
  }, []);
  return (
    <div className="PlayListsPlayerPage">
      <PlaylistPlayer playlistId={playlistId} siteId={siteId} />
    </div>
  );
};

export default PlaylistPlayerPage;
