import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Spin, Input, Divider } from 'antd';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import {
  SAVE_CHANGES,
  CANCEL,
  WARNING_TITLE,
  NOT_ABLE_TO_RECOVER_TEXT,
  EMBEDDED_PLAYLIST_PLAYER_LTI_URL,
  EMBEDDED_PLAYLIST_PLAYER_URL,
} from '../../../utils/constants-utils';
import apiCalls from '../../../services/api-calls/all';
import { errorLogger } from '../../../utils/generic-utils';
import MediaStuctureEditor from '../../molecules/media-structure-editor/media-structure-editor';
import { useRedirect } from '../../router/redirect';
import { EditModeContext } from '../../../services/providers/edit-mode-context';
import GenericConfirmModal from '../../atoms/generic-confirm-modal/generic-confirm-modal';
import MediaShare from '../../molecules/media-share/media-share';
import SharedLink from '../../molecules/shared-link/shared-link';
import { generateMediaIFrame } from '../../../utils/urls-utils';
import TitleEditor from '../../molecules/title-editor/title-editor';

import './_style.scss';

const { getSharedLink, putSharedLink, deleteSharedLink } = apiCalls();

const EditMediaStructure = ({
  mediaStructureId,
  endpoint,
  processedMediaStuctureItems,
  returnUrl,
  returnText,
  tableColumns,
  feedEndpoint,
  processFeedEndpointResults,
  onMediaStructureSubmit,
  deleteText,
  deleteFunc,
  shareAccessEndpoints,
  userId,
  siteId,
  playlist,
}) => {
  const { redirect, setUrlToRedirect } = useRedirect();
  const [mediaStructure, setMediaStructure] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState();
  const [mediaStructureItems, setMediaStructureItems] = useState([]);
  const [mediaIFrame, setMediaIFrame] = useState();
  const [unmodifiedMediaStructureObject, setUnmodifiedMediaStructureObject] = useState();
  const { setEditMode } = useContext(EditModeContext);

  useEffect(() => {
    if (mediaStructureId) searchMediaStructure();
  }, [mediaStructureId]);

  useEffect(
    () =>
      unmodifiedMediaStructureObject &&
      unmodifiedMediaStructureObject !== { mediaStructure, mediaStructureItems }
        ? setEditMode(true)
        : setEditMode(false),
    [mediaStructure, mediaStructureItems]
  );

  useEffect(() => {
    const { origin } = window.location;
    setMediaIFrame(
      generateMediaIFrame(origin, EMBEDDED_PLAYLIST_PLAYER_LTI_URL, mediaStructureId, siteId)
    );
  }, []);

  const submitMediaStructure = async () => {
    try {
      await onMediaStructureSubmit(mediaStructure, mediaStructureItems);
      setEditMode(false);
      setUrlToRedirect(returnUrl);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while editing media structure:', error });
    }
  };

  const deleteMediaStructure = async () => {
    try {
      await deleteFunc();
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while deleting media structure:', error });
    }
  };

  const searchMediaStructure = async () => {
    try {
      const response = await endpoint();
      const processedMediaItems = processedMediaStuctureItems(response);
      setMediaStructureItems(processedMediaItems);
      setMediaStructure(response.data);
      setUnmodifiedMediaStructureObject({
        mediaStructure: response.data,
        mediaStructureItems: processedMediaItems,
      });
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while getting Table DataSource:', error });
    }
  };

  const removeMediaItem = (item) => {
    const newMediaStructureItems = mediaStructureItems.filter(
      (mediaItem) => mediaItem.id !== item.id
    );
    setMediaStructureItems(newMediaStructureItems);
  };

  const handleTitleChange = (title) => {
    setMediaStructure({ ...mediaStructure, title });
  };

  const goBack = () => {
    setEditMode(false);
    setUrlToRedirect(returnUrl);
  };

  const copyIFrameToClipboard = () => {
    navigator.clipboard.writeText(mediaIFrame);
  };

  return mediaStructure ? (
    <div className="EditMediaStructureContainer">
      <GenericConfirmModal
        title={`${WARNING_TITLE}${deleteText}?`}
        content={NOT_ABLE_TO_RECOVER_TEXT}
        isModalVisible={deleteModalVisible}
        handleOk={deleteMediaStructure}
        handleCancel={() => setDeleteModalVisible(false)}
      />
      {redirect()}
      <div className="EditMediaStructureHeader">
        <TitleEditor initialValue={mediaStructure.title} onChange={handleTitleChange} />
        <ButtonPrimary size="medium" onClick={goBack} theme="round" text={returnText} />
      </div>
      <div className="EditMediaStructureBody">
        <Divider />
        <MediaStuctureEditor
          tableColumns={tableColumns}
          removeMediaItem={removeMediaItem}
          MediaItems={mediaStructureItems}
          onMediaAdd={(newItems) => setMediaStructureItems([...mediaStructureItems, ...newItems])}
          feedEndpoint={feedEndpoint}
          processFeedEndpointResults={processFeedEndpointResults}
          modifyDataSourceOrder={(data) => setMediaStructureItems(data)}
          returnUrl={returnUrl}
          mediaStructureId={mediaStructureId}
        />
        <MediaShare shareAccessEndpoints={shareAccessEndpoints} />
        {playlist && (
          <div>
            <div className="MediaShareEditorShareLinksField">
              {/* <SharedLink
              title="Private Link"
              wasShareLinkGeneratedEndpoint={getSharedLink}
              storeGenerateShareLinkEndpoint={putSharedLink}
              deleteShareLinkEndpoint={deleteSharedLink}
              isPrivate
              structureParam="playlistId"
              structureId={mediaStructureId}
              siteId={siteId}
              userId={userId}
              embededdURL={EMBEDDED_PLAYLIST_PLAYER_URL}
            /> */}
              <SharedLink
                title="Public Link"
                wasShareLinkGeneratedEndpoint={getSharedLink}
                storeGenerateShareLinkEndpoint={putSharedLink}
                deleteShareLinkEndpoint={deleteSharedLink}
                structureParam="playlistId"
                structureId={mediaStructureId}
                siteId={siteId}
                userId={userId}
                embededdURL={EMBEDDED_PLAYLIST_PLAYER_URL}
              />
            </div>
            <Divider />
            <div className="MediaShareEditorEmbedCodeField">
              <p className="TitleEmbed">Embed code</p>
              <Input.TextArea value={mediaIFrame} disabled />
              <div className="CopyButton">
                <ButtonPrimary
                  onClick={copyIFrameToClipboard}
                  text="Copy code"
                  size="small"
                  theme="round"
                />
              </div>
            </div>
            <Divider />
          </div>
        )}
      </div>
      <div className="EditMediaStructureActions">
        <ButtonPrimary
          disabled={mediaStructureItems === []}
          theme="round"
          text={SAVE_CHANGES}
          onClick={submitMediaStructure}
        />
        <ButtonPrimary type="default" theme="round" text={CANCEL} onClick={goBack} />
      </div>
      <div className="DeleteMediaStructureAction">
        <ButtonPrimary
          type="default"
          theme="round"
          text={deleteText}
          onClick={() => setDeleteModalVisible(true)}
        />
      </div>
    </div>
  ) : (
    <Spin />
  );
};

EditMediaStructure.propTypes = {
  mediaStructureId: PropTypes.string.isRequired,
  endpoint: PropTypes.func.isRequired,
  processedMediaStuctureItems: PropTypes.func,
  returnUrl: PropTypes.string.isRequired,
  returnText: PropTypes.string.isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  feedEndpoint: PropTypes.func.isRequired,
  processFeedEndpointResults: PropTypes.func,
  onMediaStructureSubmit: PropTypes.func.isRequired,
  deleteText: PropTypes.string.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  shareAccessEndpoints: PropTypes.shape({
    get: PropTypes.func,
    add: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  playlist: PropTypes.bool,
};

EditMediaStructure.defaultProps = {
  processedMediaStuctureItems: (data) => data,
  processFeedEndpointResults: (data) => data,
  playlist: false,
};

export default EditMediaStructure;
