import React, { useState, useEffect, useContext } from 'react';
import apiCalls from '../../../services/api-calls/all';
import EditMediaStructure from '../../organisms/edit-media-structure/edit-media-structure';
import { SiteContext } from '../../../services/providers/site-context';
import { UserContext } from '../../../services/providers/user-context';
import { playlistTableColumns } from '../../molecules/table-headers/playlist-items.table-header';
import { useRedirect } from '../../router/redirect';
import { PLAYLISTS_URL, BACK_TO_PLAYLISTS, DELETE_PLAYLIST } from '../../../utils/constants-utils';
import './_style.scss';

const {
  getPlaylistById,
  getPlaylistEligibleMedia,
  updatePlaylist,
  deletePlaylistById,
  listUsersWithPlaylistAccess,
  addEditAccessToUserPlaylist,
  removeEditAccessFromUserPlaylist,
} = apiCalls();

const EditPlaylistPage = () => {
  const [currentPlaylist, setCurrentPlaylist] = useState();
  const { site } = useContext(SiteContext);
  const { user } = useContext(UserContext);
  const { redirect, setUrlToRedirect } = useRedirect();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setCurrentPlaylist(params.get('id'));
  }, []);

  const onMediaStructureSubmit = (playlist, playlistItems) =>
    updatePlaylist({
      siteId: site.SiteId,
      playlistId: currentPlaylist,
      playlistTitle: playlist.title,
      playlistItems: playlistItems.map((item) => item.id),
    });

  const processedPlaylistItems = ({ data }) =>
    data.mediaItems.Items.map(({ MediaId, title, owner, created, duration }) => ({
      id: MediaId,
      media_name: title,
      owner,
      created,
      duration,
    }));

  const processFeedEndpointResults = ({ data: { Items } }) =>
    Items.map((item) => ({
      key: item.MediaId,
      id: item.MediaId,
      media_name: item.title,
      ...item,
    }));

  const getPlaylistEligibleMediaEndpoint = (mediaItemsIds) =>
    getPlaylistEligibleMedia({
      siteId: site.SiteId,
      userId: user.userId,
      ...mediaItemsIds,
    });

  const getPlaylistByIdEndpoint = () =>
    getPlaylistById({
      playlistId: currentPlaylist,
      ownerId: user.userId,
      siteId: site.SiteId,
    });

  const deleteThisPlaylist = async () => {
    await deletePlaylistById({ playlistId: currentPlaylist, siteId: site.SiteId });
    setUrlToRedirect(PLAYLISTS_URL);
  };

  const processShareAccessEndpoints = {
    get: () => listUsersWithPlaylistAccess({ siteId: site.SiteId, playlistId: currentPlaylist }),
    add: (UserId) =>
      addEditAccessToUserPlaylist({
        playlistId: currentPlaylist,
        sharedWith: UserId,
        sharedBy: user.userId,
        siteId: site.SiteId,
      }),
    remove: (shareId) => removeEditAccessFromUserPlaylist({ siteId: site.SiteId, shareId }),
  };

  return (
    <div className="EditPlaylistPageContainer" tabIndex={0} aria-label="Edit playlist page">
      {redirect()}
      {currentPlaylist && (
        <EditMediaStructure
          mediaStructureId={currentPlaylist}
          endpoint={getPlaylistByIdEndpoint}
          processedMediaStuctureItems={processedPlaylistItems}
          returnUrl={PLAYLISTS_URL}
          returnText={BACK_TO_PLAYLISTS}
          tableColumns={playlistTableColumns}
          feedEndpoint={getPlaylistEligibleMediaEndpoint}
          processFeedEndpointResults={processFeedEndpointResults}
          onMediaStructureSubmit={onMediaStructureSubmit}
          deleteFunc={deleteThisPlaylist}
          deleteText={DELETE_PLAYLIST}
          shareAccessEndpoints={processShareAccessEndpoints}
          userId={user.userId}
          siteId={site.SiteId}
          playlist
        />
      )}
    </div>
  );
};

export default EditPlaylistPage;
