import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';

import './_style.scss';

const FilterForm = ({ filterFunc, filterDisable, isFiltered }) => {
  const [form] = Form.useForm();
  const [titleValue, setTitleValue] = useState();

  const submitForm = ({ title }) => {
    filterFunc(title, form);
  };

  return (
    <div className="filterForm">
      <Form form={form} name="filterForm" onFinish={submitForm} layout="inline">
        <Form.Item name="title">
          <Input
            placeholder="filter by title"
            onChange={(event) => setTitleValue(event.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={filterDisable}>
            {isFiltered === titleValue ? 'Clear Filter' : 'Filter'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

FilterForm.propTypes = {
  filterFunc: PropTypes.func,
  filterDisable: PropTypes.bool,
  isFiltered: PropTypes.bool,
};

FilterForm.defaultProps = {
  filterDisable: false,
  filterFunc: () => {},
  isFiltered: false,
};

export default FilterForm;
