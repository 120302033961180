import React, { useContext, useState } from 'react';
import { FolderAddOutlined } from '@ant-design/icons';
import apiCalls from '../../../services/api-calls/all';
import MediaFeedContainer from '../../organisms/media-feed-container/media-feed-container';
import { useRedirect } from '../../router/redirect';
import { UserContext } from '../../../services/providers/user-context';
import { SiteContext } from '../../../services/providers/site-context';
import GenericActions from '../../molecules/table-columns/generic-actions/generic-actions.table-columns';
import {
  PLAY,
  PLAYLISTS,
  MY_PLAYLISTS,
  NEW_PLAYLIST,
  OPEN_PLAYLIST,
  SHARED_WITH_ME,
  SHARED_WITH_ME_PLAYLISTS,
  CREATE_PLAYLIST_URL,
  EDIT_PLAYLIST_URL,
  PLAYLIST_PLAYER_URL,
} from '../../../utils/constants-utils';
import { generateKeyFromString } from '../../../utils/generic-utils';
import {
  myPlaylistsColumns,
  sharedWithMeColumns,
} from '../../molecules/table-headers/playlists.table-header';
import './_style.scss';

const { getPlaylistsList, listPlaylistsWithAccess } = apiCalls();

const PlayListsPage = () => {
  const [selectedPlaylist, setSelectedPlaylist] = useState();
  const { user } = useContext(UserContext);
  const { site } = useContext(SiteContext);
  const { redirect, setUrlToRedirect } = useRedirect();

  const processPlaylistInfo = ({ data }) =>
    data.Items.map(({ PlaylistId, title, ownerId, modified }) => ({
      id: PlaylistId,
      title,
      owner: ownerId,
      last_modified: modified,
    }));
  const getPlaylistParameters = {
    userId: user.userId,
    siteId: site.SiteId,
  };
  const getSharedPlaylistsParameters = {
    asuriteId: user.userId,
    siteId: site.SiteId,
  };
  const openPlaylist = ({ id }) => {
    setSelectedPlaylist(id);
    setUrlToRedirect(EDIT_PLAYLIST_URL);
  };
  const playPlaylist = ({ id }) => {
    setSelectedPlaylist(id);
    setUrlToRedirect(PLAYLIST_PLAYER_URL);
  };
  const goToNewPlaylist = () => {
    setUrlToRedirect(CREATE_PLAYLIST_URL);
  };

  const actions = [
    { key: generateKeyFromString(PLAY), onClick: playPlaylist },
    { key: generateKeyFromString(OPEN_PLAYLIST), onClick: openPlaylist },
  ];

  const tabs = [
    {
      name: MY_PLAYLISTS,
      key: generateKeyFromString(MY_PLAYLISTS),
      tableColumns: [...myPlaylistsColumns, GenericActions({ actions })],
      processEndpointResult: processPlaylistInfo,
      endpoint: getPlaylistsList,
      endpointParameters: getPlaylistParameters,
    },
    {
      name: SHARED_WITH_ME,
      key: generateKeyFromString(SHARED_WITH_ME_PLAYLISTS),
      tableColumns: [...sharedWithMeColumns, GenericActions({ actions })],
      processEndpointResult: processPlaylistInfo,
      endpoint: listPlaylistsWithAccess,
      endpointParameters: getSharedPlaylistsParameters,
    },
  ];
  const headerInfo = {
    title: PLAYLISTS,
    ButtonText: NEW_PLAYLIST,
    ButtonIcon: <FolderAddOutlined />,
    ButtonAction: goToNewPlaylist,
  };
  return (
    <div className="PlayListsPageContainer" tabIndex={0} aria-label="Playlists list page">
      {selectedPlaylist ? redirect({ id: selectedPlaylist }) : redirect()}
      <MediaFeedContainer tabs={tabs} headerInfo={headerInfo} />
    </div>
  );
};

export default PlayListsPage;
