import React from 'react';
import MediaPlayer from '../../organisms/media-player/media-player';

import './_style.scss';

const EmbeddedPlayerLtiPage = () => {
  const params = new URLSearchParams(window.location.search);
  return (
    <div className="MediaPlayerPageContainer">
      <MediaPlayer
        id={params.get('id')}
        siteId={params.get('siteId')}
        isLti={params.get('isLti')}
      />
    </div>
  );
};

export default EmbeddedPlayerLtiPage;
